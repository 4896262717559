document.addEventListener("DOMContentLoaded", () => {
	const features = document.querySelector("#features");
	const featuresSpanElement = features.querySelector(".left .second-word");
	const parallaxElements = document.querySelectorAll(".parallax");

	const exercises = document.querySelector(".exercises");
	const devices = document.querySelector(".devices");
	const subjects = document.querySelector(".subjects");

	const textExercises = document.querySelector(".text-exercises");
	const textDevices = document.querySelector(".text-devices");
	const textSubjects = document.querySelector(".text-subjects");

	const updatePositions = () => {
		const rect = features.getBoundingClientRect();
		const distanceOnScreen =
			(rect.top - window.innerHeight) * -1 - window.innerHeight;
		const totalDistance = rect.height - window.innerHeight;
		let percentage = distanceOnScreen / totalDistance;
		//percentage is the percentage of the time, that the text is centered on screen
		if (percentage <= -0.01) features.removeAttribute("data-selected");
		if (percentage < 0.3) {
			if (percentage > -0.01) features.setAttribute("data-selected", "1");
			featuresSpanElement.setAttribute("data-selected", "1");
			exercises.toggleAttribute("data-top", false);
			exercises.toggleAttribute("data-bottom", false);

			devices.toggleAttribute("data-top", false);
			devices.toggleAttribute("data-bottom", true);

			subjects.toggleAttribute("data-top", false);
			subjects.toggleAttribute("data-bottom", true);

			textExercises.toggleAttribute("data-visible", true);
			textDevices.toggleAttribute("data-visible", false);
			textSubjects.toggleAttribute("data-visible", false);
		} else if (percentage < 0.7) {
			features.setAttribute("data-selected", "2");
			featuresSpanElement.setAttribute("data-selected", "2");
			exercises.toggleAttribute("data-top", true);
			exercises.toggleAttribute("data-bottom", false);

			devices.toggleAttribute("data-top", false);
			devices.toggleAttribute("data-bottom", false);

			subjects.toggleAttribute("data-top", false);
			subjects.toggleAttribute("data-bottom", true);

			textExercises.toggleAttribute("data-visible", false);
			textDevices.toggleAttribute("data-visible", true);
			textSubjects.toggleAttribute("data-visible", false);
		} else {
			features.setAttribute("data-selected", "3");
			featuresSpanElement.setAttribute("data-selected", "3");
			exercises.toggleAttribute("data-top", true);
			exercises.toggleAttribute("data-bottom", false);

			devices.toggleAttribute("data-top", true);
			devices.toggleAttribute("data-bottom", false);

			subjects.toggleAttribute("data-top", false);
			subjects.toggleAttribute("data-bottom", false);

			textExercises.toggleAttribute("data-visible", false);
			textDevices.toggleAttribute("data-visible", false);
			textSubjects.toggleAttribute("data-visible", true);
		}

		percentage = Math.max(0, Math.min(1, percentage));

		parallaxElements.forEach((element) => {
			let intensity = element.getAttribute("data-intensity");
			element.style.transform = `translateY(-${
				percentage * intensity * 200
			}%)`;
		});

		const mobileFeatures = document.getElementById("mobile-features");
		const mobileExercises = mobileFeatures.querySelector(".exercises");
		const mobileDevices = mobileFeatures.querySelector(".devices");
		const mobileSubjects = mobileFeatures.querySelector(".subjects");

		if(mobileExercises.getBoundingClientRect().top < 80){
			mobileFeatures.style.backgroundColor = "#8e7dbe";
		}

		if(mobileDevices.getBoundingClientRect().top < 80){
			mobileFeatures.style.backgroundColor = "#f1e3d3";
		}

		if(mobileSubjects.getBoundingClientRect().top < 80){
			mobileFeatures.style.backgroundColor = "#99c1b9";
		}
	};
	document.addEventListener("scroll", updatePositions);
	updatePositions();
});
